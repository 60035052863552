.social-buttons {
    float: right;
    margin-right:20px;
    margin-top:auto;
    margin-bottom:auto;
  }
  
.social-button {
    margin-left: 3px;
    margin-right: 3px;
    color: white;
}

.social-link {
    color: whitesmoke;
}