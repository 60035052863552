div.box {
    border: 2px;
    border-style: solid;
    border-color: white;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-width: 300px;
    background: black;
  }

  div.box-contents {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
  }