.MenuButton {
    text-align: center;
    width: fit-content;
    display: flex;
    padding: 2px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    cursor: pointer;    
    text-decoration: none;
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 5px;
}

.MenuButton:hover {
    background-color: black;
    color:white;
}

.MenuButtonContainer {
    float: left;
}