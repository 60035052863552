
div.recording-image {
    border-width: 1;
    border-style: solid;
    border-color: black;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
}

img.recording-image {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
}

div.recording-container {
    width: 175px;
    height: auto;
    text-align: center;
    background-color: #222; 
    padding: 10px;
    margin: 20px;
}

div.recording-title {
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
}

div.recording-year {
    text-align: center;
    color: darkgray;
}

div.recording-artist {
    text-align: center;
    color: lightgray;
}


div.recording-row {
    width: fit-content;
    height: fit-content;
    display: flex;
}
