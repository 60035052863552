.Event-main {
    margin-top :"20px";
    width: fit-content;
    background: #444444
}

.Event-main-odd {
    margin-top :"20px";
    width: fit-content;
    background: #777777 
}


.Event-title {
    font-weight: bold;
}

.Event-date {
    font-weight: bold;
}

.Event-time {
    font-weight: bold;
}

.Event-location {
    font-weight: normal
}

.Event-body {
    font-weight: normal;
    margin-top: -5px;
    margin-left: 10px;
    max-width: 500px;
    padding-bottom: 10px;
}