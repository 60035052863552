.App {
  text-align: left;
}


.App-header {
  background-color: black;
  align-items: left;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: -0.5em;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-title {
  cursor: pointer;    
  color: white;
  text-decoration: none;
}

.App-menu {
    display: flex;
    margin-top: -1em;
    padding-bottom: 0.5em;
    border-bottom: 2px solid white;
    display: flex;
    flex-wrap: wrap;
}

.App-body {
  margin-left: 2em;
  margin-right: 2em;
}

.App-footer {
  border-top: 1px solid white;
  padding-left: 2em;
  padding-top: 0.5em;
  padding-bottom: 2em;
}



