.terminalContainer {
    :global
      .crt-terminal
      .crt-terminal__command-line
      .crt-command-line
      .crt-command-line__input-wrapper
      .crt-command-line__input-string
      .crt-cursor-symbol.crt-character {
        color: red;
        background-color: yellowgreen;
  
        & :local {
          animation: blink-character-custom 1s steps(1, end) infinite;
        }
    }
  
    @keyframes blink-character-custom {
      50% {
        color: yellowgreen;
        background-color: transparent;
      }
    }

    width: 1000px;
    height: 600px;
  }