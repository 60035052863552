.press-article {
    margin-bottom: 1em;
}

.press-outlet {
    font-weight: bold;
}

.press-title {
    color: whitesmoke;
}

.press-container {
    margin: 1em;
}

.press-main {
    margin-top: 1em;
    margin-bottom: 2em;
}